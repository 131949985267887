import React, { useState } from 'react';
import GuildCard from '../GuildCard';

function GuildApplicationForm({ onFormSubmit }) {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [guildName, setGuildName] = useState('');
  const [logo, setLogo] = useState('');
  const [description, setDescription] = useState('');
  const [showDiscord, setShowDiscord] = useState(false);
  const [showYoutube, setShowYoutube] = useState(false);
  const [discord, setDiscord] = useState('');
  const [youtube, setYoutube] = useState('');

  const handleDiscordChange = (event) => {
    setDiscord(event.target.value);
  };

  const handleYoutubeChange = (event) => {
    setYoutube(event.target.value);
  };

  const handleShowDiscordChange = (event) => {
    setShowDiscord(event.target.checked);
  };

  const handleShowYoutubeChange = (event) => {
    setShowYoutube(event.target.checked);
  };

  return (
    <form action="https://formspree.io/f/xpzvldzq" method="POST" className='guildApp r-background'>
      <div className='guildAppHeader'>Guild Application</div>
      <div className='guildAppCont'>

        <div className='guildForm'>
          <div>
            <label htmlFor='name'>Applicant name:</label>
            <input
              id='name'
              type='text'
              name='applicantName'
              placeholder='Name'
              value={name}
              onChange={(event) => setName(event.target.value)}
              required
            />
          </div>

          <div>
            <label htmlFor='email'>Your email:</label>
            <input
              id='email'
              type="email"
              name="applicantEmail"
              placeholder="Email Address"
              value={email}
              onChange={(event) => setEmail(event.target.value)}
              required
            />
          </div>

          <div>
            <label htmlFor='guildName'>Guild Name:</label>
            <input
              id='guildName'
              type='text'
              name='guildName'
              placeholder="Guild Name"
              value={guildName}
              onChange={(event) => setGuildName(event.target.value)}
              required
            />
          </div>

          {/* File upload handling should be adjusted if necessary */}
          <div>
            <label htmlFor="picture">Upload a logo (PNG or JPG only):</label>
            <input
              id="picture"
              type="file"
              name='guildLogo'
              accept="image/png, image/jpeg"
            />
          </div>

          <div>
            <label htmlFor="has-discord">Does your guild have a Discord?</label>
            <input
              id="has-discord"
              type="checkbox"
              name="has-discord"
              checked={showDiscord}
              onChange={handleShowDiscordChange}
            />
            {showDiscord && (
              <input
                id="discord"
                type="text"
                name="discord"
                placeholder="Discord Link"
                value={discord}
                onChange={handleDiscordChange}
                required={showDiscord}
              />
            )}
          </div>
          <div>
            <label htmlFor="has-youtube">Does your guild have a YouTube?</label>
            <input
              id="has-youtube"
              type="checkbox"
              name="has-youtube"
              checked={showYoutube}
              onChange={handleShowYoutubeChange}
            />
            {showYoutube && (
              <input
                id="youtube"
                type="text"
                name="youtube"
                placeholder="YouTube Link"
                value={youtube}
                onChange={handleYoutubeChange}
                required={showYoutube}
              />
            )}
          </div>

          <div>
            <label htmlFor='description'>Write a 1-2 sentence description about your guild:</label>
            <textarea
              id='description'
              name='description'
              placeholder='Guild description'
              value={description}
              onChange={(event) => setDescription(event.target.value)}
              required
            />
          </div>
        </div>

        <div className='guildCardCont'>
          <GuildCard
            name={guildName}
            imageUrl={logo}
            description={description}
            discord={discord}
            youtube={youtube}
          />
        </div>

        <button className='guildAppSubmitBtn' type='submit'>Submit</button>
      </div>
    </form>
  );
}

export default GuildApplicationForm;
