import React from 'react'

const Others = () => {
    return (
        <div className='r-background'>
            <div className='underDevelopment'>
            This page is COMING SOON! <span>Currently under development</span>
            </div>
            <div className='monstersBG'></div>
        </div>
      )
}

export default Others
