import React from 'react';

const About = () => {
  return (
    <main className='aboutPage r-background'>
        <header className='aboutHeader'>
            <h1 className='r-aboutTitles'>About Mirandus Hub</h1>
        </header>
        <div className='aboutContentCont'>

            <p>
            As a huge fan of Mirandus, I decided to create a fan page dedicated to the game. Although I will continue to create youtube content for the game, I wanted to create a centralized resource that everyone, from new players to people who have been around since the beginning, could benefit from.
            </p>

            <hr />
            <p className='centered'>~~~~~</p>

            
            <p>
            One of the main reasons I created the fan page was because I saw a lack of centralization for information about Mirandus. While there were scattered forums and discord groups discussing the game, there wasn't a single 'hub' where players could go to find all the information, photos, etc they needed.
            </p>

            <hr />
            <p className='centered'>~~~~~</p>


            <p>
            That's where my fan page comes in. I wanted to create a one-stop-shop for all things Mirandus, where players could find updates, ask questions, share strategies, and connect with other fans of the game. Although there are not forums yet, I would eventually like to include a way for players to communicate thoughts and ideas on-site.
            </p>

            <hr />
            <p className='centered'>~~~~~</p>


            <p>
            In addition to providing a central resource for Mirandus players, I also wanted to create a welcoming and inclusive community. I want the fan page to be a place where players can come together and share their love for the game, regardless of their experience level or background.
            </p>

            <hr />
            <p className='centered'>~~~~~</p>


            <p>
            Overall, I created the Mirandus fan page because I wanted to provide a valuable resource for the community and bring players together in one place. I hope that it becomes a go-to destination for anyone looking for information.
            </p>

            <p className='centered'>~~~~~</p>


            <section className="donation-cta">
              <p>If you would like to sponsor the development of this site, <strong>donations are welcomed!</strong> My address is 0x803d1CF1684aA1f0236fc3218c73e45F2Fda4362. It will all go towards more Mirandus assets. xD</p>
            </section>

            <hr />
            <p className='centered'>~~~~~</p>


            <p>
            Sincerely, thank you guys for helping create this amazing community,
            </p>
            <p id='sig'>HammerHammond23</p>

        </div>

    </main>
  );
}

export default About;
